import React from "react"

import { Wrapper, Section } from "../components/container"
import { Headline, Paragraph } from "../components/ui/text"

import Video from "../images/video.mp4"

const VideoSection = () => (
  <Section ID="eintagimatelier">
    <Wrapper>
      <div className="grid lg:grid-cols-3 gap-16">
        <div>
          <Headline>Ein Tag im Atelier</Headline>
          <Paragraph>
            Zu deinem Abstecktermin bringst du dein Kleid und die Schuhe mit die
            du an deiner Hochzeit tragen möchtest. Das ist wichtig um die Länge
            festlegen zu können. Das Saumkürzen und das Anpassen der Rumpflänge
            und Weite sind die häufigsten Änderungen.
            <br /> <br /> Aber ich freue mich auch immer über Bräute mit
            Phantasie und Kreativität! Du möchtest anstatt Tüll lieber Chiffon
            als Rock? Oder umgekehrt? Du möchtest Ärmel? Oder eine Schnürung
            anstatt dem Reißverschluss damit der Babybauch Platz hat? ...
          </Paragraph>
        </div>
        <div className="relative">
          <video controls>
            <source src={Video} type="video/mp4" />
          </video>
        </div>

        <div className=" flex flex-cols items-end">
          <Paragraph>
            ... Es wurden hier auch schon aus zwei Kleidern eins gemacht. Nicht
            ganz üblich aber warum nicht, wenn dabei das Traumkleid entsteht?{" "}
            <br /> <br />
            Schreib mich an oder vereinbare einen Termin um zu schauen was
            möglich ist!
            <br />
            <br />
            Da in diesen Räumen auch eine komplette Kollektion an Zweiteilern
            und Brautkleidern entsteht, habe ich viele Materialien zur Auswahl
            um deine Ideen verwirklichen zu können. Ich freu mich auf dich!
          </Paragraph>
        </div>
      </div>
    </Wrapper>
  </Section>
)

export default VideoSection
