import React from "react"
import PageData from "../data/frontpage.json"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/ui/hero"
import Intro from "../components/intro"
import VideoSection from "../components/videoSection"
import About from "../components/about"
import Contact from "../components/contact"

import { Headline, Paragraph } from "../components/ui/text"
import { Section, Wrapper } from "../components/container"
import { DateButton } from "../components/ui/button"

import Overscroll from "../images/overscroll.jpg"

const OverscrollSection = styled.div`
  background-image: url(${Overscroll});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;

  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    background-attachment: fixed;
    height: 450px;
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <Intro />
    <OverscrollSection />
    <VideoSection />
    <About />
    <Section ID="termin">
      <Wrapper>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className=" mb-12 md:mb-0 md:mr-8">
            <Headline>In Sachen Zeit...</Headline>
            <Paragraph>
              Wann ist eigentlich der beste Zeitpunkt ein Brautkleid zu ändern?
              Klassisch setzt man den Abstecktermin 4-6 Wochen vor der Hochzeit
              an. <br /> Bei Umstandskleidern (oder normalen Kleidern die dazu
              umgearbeitet werden sollen;-) lege ich die Termine 1-2 Wochen vor
              das Hochzeitsdatum, besonders wenn die Hochzeit im 3. Trimester
              stattfindet. Du landest dann auf der Prioritäten Liste ganz oben.{" "}
              <br /> <br />
              Wenn du dir sagst dass du in deinem Gewicht stabil bleibst oder
              dein Kleid eh eine Schnürung hat (die verschmerzt kleinere
              Gewichtsschwankungen) kannst du gerne den Winterrabatt von 20 %
              mitnehmen. Den gibt's von November bis Januar.
            </Paragraph>
            <DateButton>Termin sichern</DateButton>
          </div>
          <div>
            <StaticImage
              src="../images/clock.jpg"
              quality={95}
              height={400}
              width={600}
              placeholder="blurred"
              objectFit="cover"
              alt="Ein Bild von einer Uhr"
              title="Ein Bild von einer Uhr"
            />
            {/* <Headline>{PageData.title}</Headline>
            <Paragraph>{PageData.text}</Paragraph> */}
          </div>
        </div>
      </Wrapper>
    </Section>
    <Contact ID="kontakt" />
  </Layout>
)

export default IndexPage
