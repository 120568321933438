import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Headline } from "./ui/text"
import { Section, Wrapper } from "./container"
import { Button } from "./ui/button"

const Intro = () => {
  return (
    <Section ID="intro">
      <Wrapper>
        <Headline>
          Klar, unsere Kleidung sieht viel besser an uns aus, wenn man merkt
          dass wir uns darin wohlfühlen...
        </Headline>
        <div className="mt-16 grid grid-cols-1 gap-12 lg:gap-10 md:grid-cols-2">
          <div className="relative h-full md:ml-8">
            <p className="hidden md:block transform absolute bottom-0 -rotate-90 -translate-y-32 -translate-x-32 ">
              ––––––welcome to Tailor's Nook
            </p>
            <StaticImage
              src="../images/Intro.jpg"
              style={{
                height: "100%",
                maxHeight: "700px",
                maxWidth: "500px",
              }}
              quality={95}
              placeholder="blurred"
              objectFit="cover"
              layout="fullWidth"
              alt="Ein Bild von einer Braut"
              title="Ein Bild von einer Braut"
            />
          </div>
          <div className="md:mt-14">
            <p className="max-w-xs">
              ... die richtige Passform ist dabei entscheidend. Dein Kleid kann
              noch so traumhaft sein, aber höchstwahrscheinlich ist es in einer
              Standard Konfektionsgröße gearbeitet worden. Wie wir alle wissen
              gibt es Standardgrößen aber keine Standardkörper. Und das ist auch
              gut so!
            </p>
            <h3 className="text-2xl text-secondary max-w-sm mb-5 mt-12">
              Willkommen im Schneideratelier Tailor's Nook.
            </h3>
            <p className="max-w-xs mb-8 ">
              Hier werden Brautkleider und ähnlich feine Roben passend gemacht.
              Außerdem ist es das Heim der Marke roobarb.nation
            </p>
            <Button link="/roobarb">roobarb was?</Button>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}

export default Intro
