import React from "react"
import styled from "styled-components"

import HeroImage from "../../images/hero.jpg"
import Logo from "../../images/logo_symbol_white.svg"

const HeroSection = styled.section`
  background: linear-gradient(
      90deg,
      rgba(33, 34, 39, 0.5) 0%,
      rgba(33, 34, 39, 0.5) 100%
    ),
    url(${HeroImage});
  background-size: cover;
  background-position: top center;

  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    background-attachment: fixed;
  }
`

const Hero = () => {
  return (
    <HeroSection
      className="mb-16 flex items-center h-screen"
      style={{ minHeight: "700px" }}
    >
      <div className="container mx-auto px-6 flex flex-col items-center">
        <div className=" max-w-prose flex flex-col items-center">
          <h1 className="text-white text-3xl mt-6 md:mt-0 lg:text-5xl leading-tight text-center mb-3 leading-relaxed ">
            The secret of great style is to feel good in what you wear.
          </h1>
          <p className="text-lg mb-8 text-white">–Inès de la Fressange</p>
          <img
            src={Logo}
            alt="Tailorsnook Logo"
            title="Tailorsnook Logo"
            style={{ height: "45px" }}
          />
          {/* <div className="lg:hidden">
            <LightButton>Termine Updates</LightButton>
          </div> */}
        </div>
      </div>
    </HeroSection>
  )
}

export default Hero
