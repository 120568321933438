import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Wrapper } from "./container"
import { LightHeadline, LightParagraph } from "./ui/text"

import Dina from "../images/dina.jpg"

const AboutSection = styled.section`
  background: linear-gradient(
      180deg,
      rgba(69, 78, 74, 0) 0%,
      rgba(69, 78, 74, 1) 28%
    ),
    url(${Dina});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    background: linear-gradient(
        90deg,
        rgba(69, 78, 74, 0) 0%,
        rgba(69, 78, 74, 1) 40%
      ),
      url(${Dina});
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media only screen and (min-width: 1536px) {
    background: #454e4a;
  }
`

const Test = styled.div`
  @media only screen and (min-width: 1536px) {
    background: linear-gradient(
        90deg,
        rgba(69, 78, 74, 0) 0%,
        rgba(69, 78, 74, 1) 40%
      ),
      url(${Dina});
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const About = () => {
  return (
    <AboutSection id="about" className="py-28 2xl:py-0">
      <Wrapper>
        <Test className="md:flex justify-end md:w-full">
          <div className="md:w-max mt-24 md:mt-0 2xl:py-28">
            <LightHeadline>About me</LightHeadline>
            <LightParagraph>
              Ich bin Nadine, gelernte Maßschneiderin im Handwerk, Mum meiner
              beiden coolen Kids, Pflanzenfreak und möchtegern Künstlerin. Das
              einzige was ich in Mathe jemals konnte war Geometrie und
              Körperberechnung (Perfekt für die Schnittkonstruktion! Glück
              gehabt). <br /> <br /> Seit einigen Jahren bin ich im Brautsegment
              unterwegs. Es ist eine absolute Freude mit so glücklichen Menschen
              zusammenzuarbeiten! <br />
              Viele von euch finden es genauso bedauerlich wie ich, dass die Eco
              Fashion Bewegung nur sehr langsam in die Brautmode Einzug hält.
              Das sollte sich ändern. Um meinen Beitrag dazu zu leisten,
              entsteht hier die Marke{" "}
              <Link
                to="/roobarb"
                class="font-bold hover:text-hovering transition duration-200 ease-in-out text-md text-lg"
              >
                [roobarb.nation]
              </Link>
              .
              <br />
              <br /> All das kommt in diesen schönen Altbauräumen zusammen. Hier
              gibt's gemütlichen Plausch bei nem Käffchen und Klamotten die
              botanische Namen tragen... Let's meet ; )
            </LightParagraph>
          </div>
        </Test>
      </Wrapper>
    </AboutSection>
  )
}

export default About
