import React from "react"
import { Link } from "gatsby"

export const Button = ({ children, link }) => {
  return (
    <div className="uppercase text-xs text-secondary lg:hover:bg-secondary lg:hover:text-white transition duration-300 ease-in-out rounded-full border border-solid border-secondary mt-7 mb-3 py-3 px-5 w-max cursor-pointer">
      <Link to={link}>{children}</Link>
    </div>
  )
}

export const DateButton = ({ children }) => {
  return (
    <div className="uppercase text-xs text-secondary lg:hover:bg-secondary lg:hover:text-white transition duration-300 ease-in-out rounded-full border border-solid border-secondary mt-7 mb-3 py-3 px-5 w-max cursor-pointer">
      <a
        href="mailto:mail@tailorsnook.de?subject=Termin%20sichern"
        title="Mail schreiben"
      >
        {children}
      </a>
    </div>
  )
}

export const LightButton = ({ children }) => {
  return (
    <div className="uppercase text-xs text-white transition duration-300 ease-in-out rounded-full border border-solid border-white mt-7 mb-3 py-3 px-5 w-max cursor-pointer">
      <Link to="#termin">{children}</Link>
    </div>
  )
}
