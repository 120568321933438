import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Wrapper } from "../components/container"
import { Headline } from "../components/ui/text"

import Background from "../images/overscroll_black.jpg"

const ContactSection = styled.section`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
    background-image: url(${Background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0;
  } ;
`

const Card = () => (
  <div className="md:bg-white pb-7 md:p-12 w-max max-w-prose">
    <Headline>Sag doch mal Hallo</Headline>
    <div className="grid md:grid-cols-2 my-6 gap-3 md:gap-12">
      <div>
        <p className="font-bold text-font">Tailors Nook</p>
        <p className="text-font">Moislinger Allee 22a</p>
        <p className="text-font">23558 Lübeck</p>
      </div>
      <div>
        <a
          className="text-font lg:hover:text-secondary"
          href="tel:015737530267"
          title="Tailors Nook anrufen"
        >
          0157 37530267
        </a>{" "}
        <br />
        <a
          className="text-font lg:hover:text-secondary"
          href="mailto:info@tailorsnook.de?subject=Anfrage%20über%20Webseite"
          title="Mail an Tailors Nook schreiben"
        >
          info@tailorsnook.de
        </a>{" "}
        <br />
        <a
          href="https://www.instagram.com/tailors.nook/"
          target="_blank"
          title="Tailors Nook auf Instagram"
          rel="noopener noreferrer"
          className="text-font lg:hover:text-secondary"
        >
          @tailors.nook
        </a>
      </div>
    </div>
    <p className="font-bold text-font">Openings</p>
    <p className="text-font">Termine nach Vereinbarung</p>
  </div>
)

const Contact = () => {
  return (
    <div id="kontakt">
      {/* Desktop Verion */}
      <ContactSection>
        <Wrapper>
          <Card />
        </Wrapper>
      </ContactSection>
      {/* Mobile Verion */}
      <div className="md:hidden">
        <Wrapper>
          <Card />
        </Wrapper>
        <StaticImage
          src="../images/overscroll_black.jpg"
          style={{ maxHeight: "300px" }}
          quality={95}
          placeholder="blurred"
          objectFit="cover"
          layout="fullWidth"
          alt="Ein Bild von einer Braut im Atelier"
        />
      </div>
    </div>
  )
}

export default Contact
