import React from "react"
import styled from "styled-components"

import Logo from "../../images/logo_symbol.svg"

const HeadlineStyle = styled.h2`
  position: relative;
  ::before {
    content: url(${Logo});
    display: block;
    width: 28px;
    position: absolute;
    top: -20px;
    left: -7px;
    opacity: 0.35;
  }
`

export const Headline = ({ children }) => {
  return (
    <HeadlineStyle className="text-primary text-3xl max-w-prose mb-3">
      {children}
    </HeadlineStyle>
  )
}

export const Paragraph = ({ children }) => {
  return <p className="text-primary max-w-prose">{children}</p>
}

export const LightHeadline = ({ children }) => {
  return (
    <HeadlineStyle className="text-white text-3xl max-w-prose mb-3">
      {children}
    </HeadlineStyle>
  )
}

export const LightParagraph = ({ children }) => {
  return <p className="text-white max-w-prose">{children}</p>
}
